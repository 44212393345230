<template>
  <ui-component-modal
    modalTitle="Edit capacity"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveSpaceCapacity"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content> </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import spaceProvider from '@/providers/space'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false
    }
  },

  computed: {},

  created() {},

  methods: {
    saveSpaceCapacity() {}
  }
}
</script>
